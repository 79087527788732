import React, { useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { LoadingAnimation } from "@/lib";

const IndigenousMap = ({ site, territories }) => {
    const [selectedTerritory, setSelectedTerritory] = React.useState({});
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isAlertOpen, setAlertOpen] = React.useState(false);

    const showAlert = () => {
        setAlertOpen(true);
    };

    const closeAlert = () => {
        setAlertOpen(false);
    };

    function addMarker(map, lng, lat) {
        const marker = new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map);
        map.setCenter([lng, lat]);
        marker.setLngLat([lng, lat]);
    }

    function createCircle(map: any, lng: number, lat: number, radiusInM: number, points = 64) {
        const radiusInKm = radiusInM / 1000;
        const output = [];
        const dx = radiusInKm / (111.32 * Math.cos((lat * Math.PI) / 180));
        const dy = radiusInKm / 110.574;
        let theta, x, y;

        for (let i = 0; i < points; i++) {
            theta = (i / points) * (2 * Math.PI);
            x = dx * Math.cos(theta);
            y = dy * Math.sin(theta);

            output.push([lng + x, lat + y]);
        }

        output.push(output[0]);

        const circleSourceData = {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [
                    {
                        type: "Feature",
                        properties: {},
                        geometry: {
                            type: "Polygon",
                            coordinates: [output],
                        },
                    },
                ],
            },
        };

        map.addSource("circle-radius", circleSourceData);

        map.addLayer({
            id: "circle-radius-fill",
            type: "fill",
            source: "circle-radius",
            paint: {
                "fill-color": "blue",
                "fill-opacity": 0.1,
            },
        });

        map.addLayer({
            id: "circle-radius-outline",
            type: "line",
            source: "circle-radius",
            paint: {
                "line-color": "blue",
                "line-opacity": 0.5,
                "line-width": 2,
            },
        });
    }

    useEffect(() => {
        setIsLoading(true);
        if (!process.env.API_KEY || !process.env.SECRET_KEY) {
            console.error("Missing API credentials");
            setIsLoading(false);
            return;
        }

        if (typeof site.latlng.lng !== "number" || typeof site.latlng.lat !== "number") {
            console.error("Invalid site coordinates");
            setIsLoading(false);
            return;
        }

        mapboxgl.accessToken = "pk.eyJ1IjoiYW5kcmUtaGlja21hbm4iLCJhIjoiY2xoNjR4enBkMDE3cjNqcGc0aG93ZzlueSJ9.JH3ClP3oIf2uvc4ZpFvjJQ";
        const map = new mapboxgl.Map({
            container: "map",
            style: "mapbox://styles/mapbox/streets-v11",
            center: [site.latlng.lng, site.latlng.lat],
            zoom: 9,
        });

        map.on("load", () => {
            // Add site marker
            addMarker(map, site.latlng.lng, site.latlng.lat);

            // Add radius circle
            const siteRadius = parseInt(localStorage.getItem("siteRadius")) || 5000;
            createCircle(map, site.latlng.lng, site.latlng.lat, siteRadius);

            // Add indigenous territories if available
            if (territories && territories.length > 0) {
                // Find the closest territory
                let closestTerritory = null;
                let minDistance = Infinity;

                territories.forEach((territory) => {
                    if (territory.properties.distance_meters < minDistance) {
                        minDistance = territory.properties.distance_meters;
                        closestTerritory = territory;
                    }
                });

                map.addSource("territories", {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: territories.sort((a, b) => a.properties.distance_meters - b.properties.distance_meters),
                    },
                });

                map.addLayer({
                    id: "territory-fills",
                    type: "fill",
                    source: "territories",
                    paint: {
                        "fill-color": "#f08",
                        "fill-opacity": 0.4,
                    },
                });

                // Add closest distance line if we have a closest territory
                if (closestTerritory && closestTerritory.geometry.coordinates) {
                    // Get the closest point from the territory to the site
                    const closestPoint =
                        closestTerritory.properties.closest_point ||
                        (closestTerritory.geometry.type === "Polygon"
                            ? closestTerritory.geometry.coordinates[0][0]
                            : closestTerritory.geometry.coordinates[0]);

                    map.addSource("lineToClosest", {
                        type: "geojson",
                        data: {
                            type: "Feature",
                            properties: {},
                            geometry: {
                                type: "LineString",
                                coordinates: [[site.latlng.lng, site.latlng.lat], closestPoint],
                            },
                        },
                    });

                    map.addLayer({
                        id: "lineToClosest",
                        type: "line",
                        source: "lineToClosest",
                        layout: { "line-join": "round", "line-cap": "round" },
                        paint: { "line-color": "red", "line-width": 4 },
                    });
                }

                // Add click interaction
                map.on("click", "territory-fills", (e) => {
                    if (e.features.length > 0) {
                        const feature = e.features[0];
                        setSelectedTerritory(feature.properties);
                        showAlert();
                    }
                });

                // Change cursor on hover
                map.on("mouseenter", "territory-fills", () => {
                    map.getCanvas().style.cursor = "pointer";
                });
                map.on("mouseleave", "territory-fills", () => {
                    map.getCanvas().style.cursor = "";
                });
            }

            setIsLoading(false);
        });

        return () => map.remove();
    }, [site, territories]);

    return (
        <div style={{ position: "relative", width: "100%", height: "500px" }}>
            <div id="map" style={{ width: "100%", height: "100%" }} />
            {isLoading && (
                <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                    <LoadingAnimation />
                </div>
            )}
            <AlertWindow data={selectedTerritory} isOpen={isAlertOpen} onClose={closeAlert} />
        </div>
    );
};

export default IndigenousMap;

const AlertWindow = ({ data, isOpen, onClose }) => {
    if (!isOpen || !data) return null;

    return (
        <div className="alert-window">
            <h3>GID: {data?.gid || "N/A"}</h3>
            <h3>Name: {data?.name || "N/A"}</h3>
            <p>Orig. Name: {data?.orig_name || "N/A"}</p>
            <p>Type: {data?.desig_eng || "N/A"}</p>
            <p>Reported Area (km²): {data?.rep_area ? data.rep_area.toFixed(3) : "N/A"}</p>
            <p>Status Year: {data?.status_yr || "N/A"}</p>
            <button onClick={onClose}>Close</button>
        </div>
    );
};
