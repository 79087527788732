import React from "react";
import { colors, style, StyleSheet } from "@/utils/style";
import Dropdown from "@/utils/Dropdown";
import { fetchScopes } from "@/rest/apiHelper";
import { useProject, useTeam } from "@/context";
import { useActivities } from "@/utils/customHooks";
import { CheckHandler, CompanyEconomicActivity, iScopeChecked } from "@/types/sleap";
import CryptoJS from "crypto-js";
import Checkbox from "@/utils/Checkbox";
import { useNavigate } from "react-router-dom";
import { AppContext, NavigationChanged, ProjectExploreRastersChanged } from "@/context";
import { getHomeUrl } from "@/utils";

const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();

const header = {
    headers: {
        Authorization: `Bearer ${encryptedToken}`,
        "Content-Type": "application/json",
    },
};

const scopes = ["own", "downstream", "upstream"];

export default function Scope({ checkHandler }: CheckHandler): JSX.Element {
    const [scopeOptions, setScopeOptions] = React.useState<iScopeChecked[]>([]);
    const [isSaving, setIsSaving] = React.useState(false);
    const [saveSuccess, setSaveSuccess] = React.useState(false);
    const [companyActivities, setCompanyActivities] = React.useState<CompanyEconomicActivity[]>([]);
    const team = useTeam();
    const project = useProject();
    const navigate = useNavigate();
    const { dispatch } = React.useContext(AppContext);

    const handleCheckChange = React.useCallback(
        (index: number) => {
            const updatedOptions = [...scopeOptions];
            updatedOptions[index].checked = !updatedOptions[index].checked;
            setScopeOptions(updatedOptions);
        },
        [scopeOptions],
    );

    async function getCompanyEconomicActivities(companyId: number): Promise<{ data: CompanyEconomicActivity[] }> {
        try {
            const response = await fetch(
                `${process.env.KUYUA_APP_BACKEND_URL}/companies-economic-activities?companyId=${companyId}`,
                header,
            );
            const data = await response.json();
            setCompanyActivities(data.data);
            return data;
        } catch (error) {
            console.error("Error fetching economic activities:", error);
            return { data: [] };
        }
    }

    const fetchCompanyData = React.useCallback(
        async (companyId: number) => {
            try {
                const checkCompanyUrl = `${process.env.KUYUA_APP_BACKEND_URL}/company?id=${companyId}`;
                const response = await fetch(checkCompanyUrl, header);
                const data = await response.json();

                setScopeOptions(
                    scopes.map((scope: string, i: number) => ({
                        name: scope,
                        checked: data.scope.includes(scope),
                        order: i,
                        id: i,
                    })),
                );
            } catch (error) {
                console.error("Error fetching company data:", error);
            }
        },
        [team.id],
    );

    React.useEffect(() => {
        if (team.id) {
            fetchCompanyData(team.id);
            getCompanyEconomicActivities(team.id);
        }
    }, [fetchCompanyData, team.id]);

    const [activities, handleSelect] = useActivities(null, companyActivities);

    const isComplete = React.useMemo(
        () =>
            activities.selected.industry_ids.length > 0 &&
            activities.selected.sub_industry_ids.length > 0 &&
            activities.selected.economic_activity_ids.length > 0,
        [activities.selected],
    );

    React.useEffect(() => {
        !project.is_complete && checkHandler(isComplete);
    }, [isComplete]);

    async function saveScope() {
        if (!isComplete) {
            return;
        }

        setIsSaving(true);
        setSaveSuccess(false);

        try {
            const response = await fetch(`${process.env.KUYUA_APP_BACKEND_URL}/companies-economic-activities`, {
                ...header,
                method: "post",
                body: JSON.stringify({
                    activityIds: activities.selected.economic_activity_ids,
                    companyId: team.id,
                    companyScope: scopeOptions.filter((s) => s.checked).map((s) => s.name),
                }),
            });
            await response.json();
            setSaveSuccess(true);
            const url = getHomeUrl(team) + `/understand`;
            navigate(url);
        } catch (error) {
            console.log("error ==>> ", error);
        } finally {
            setIsSaving(false);
        }
    }

    return (
        <div style={{ ...style.flexColumn, padding: 30 }}>
            <div>
                <p style={{ fontSize: 14 }}>The analysis should be based on</p>
                <div
                    style={{
                        ...style.centerFlex,
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: 10,
                    }}
                >
                    {scopeOptions.map((scope, i) => (
                        <Checkbox
                            checked={scope.checked}
                            label={scope.name}
                            key={i}
                            checkHandler={() => handleCheckChange(i)}
                            color={colors.brightBlue}
                        />
                    ))}
                </div>
            </div>

            <div>
                <Dropdown
                    label="Section"
                    value="industry"
                    options={activities.options.industries}
                    handler={(selected) => handleSelect(selected, "industry")}
                    selected={activities.selected.industry_ids}
                    multiple
                />

                {activities.selected.industry_ids.length > 0 && (
                    <Dropdown
                        label="Division"
                        value="sub_industry"
                        options={activities.options.sub_industries.filter((div) =>
                            activities.selected.industry_ids.includes(div.section_id),
                        )}
                        handler={(selected) => handleSelect(selected, "sub_industry")}
                        selected={activities.selected.sub_industry_ids}
                        multiple
                    />
                )}

                {activities.selected.sub_industry_ids.length > 0 && (
                    <Dropdown
                        label="Economic Activities"
                        value="economic_activity"
                        options={activities.options.economic_activities.filter((act) =>
                            activities.selected.sub_industry_ids.includes(act.division_id),
                        )}
                        handler={(selected) => handleSelect(selected, "economic_activity")}
                        selected={activities.selected.economic_activity_ids}
                        multiple
                    />
                )}
            </div>
            <button
                style={{
                    ...style.actionButton,
                    marginTop: 10,
                    width: 150,
                    opacity: !isComplete || isSaving ? 0.5 : 1,
                    cursor: !isComplete || isSaving ? "not-allowed" : "pointer",
                }}
                onClick={() => saveScope()}
                disabled={!isComplete || isSaving}
            >
                {isSaving ? "Saving..." : "Apply"}
            </button>
            {saveSuccess && <div style={{ color: colors.green, marginTop: 10, fontSize: 14 }}>Successfully saved!</div>}
        </div>
    );
}

const styles = StyleSheet.create({
    disabledButton: {
        backgroundColor: colors.opaqueWhite,
        color: "white",
    },
});
